function Svg(props) {
  return (
    <svg height="100%" width="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" {...props}>
      <linearGradient id="a" gradientUnits="userSpaceOnUse" x1="512" x2="512" y1="1024">
        <stop offset="0" stopColor="#1d76f2" />
        <stop offset="1" stopColor="#1ac2fb" />
      </linearGradient>
      <path d="M0 0h1024v1024H0z" fill="url(#a)" />
      <path
        d="M333 745.5l.095.03-34.547 59.837c-12.61 21.842-40.541 29.326-62.383 16.715-21.841-12.609-29.325-40.54-16.715-62.382l25.45-44.08 2.442-4.23c4.362-6.271 15.126-17.089 36.657-15.056 0 0 50.667 5.499 54.333 31.833.001 0 .501 8.666-5.332 17.333zm489.998-152.833H715.267c-7.336-.491-10.541-3.111-11.798-4.634l-.08-.139L588.066 388.15l-.149.1-6.917-9.917c-11.334-17.333-29.333 27-29.333 27-21.49 49.399 3.049 105.564 11.606 122.539l160.176 277.433c12.609 21.842 40.54 29.326 62.382 16.715 21.841-12.609 29.325-40.54 16.715-62.382l-40.054-69.374c-.775-1.68-2.126-6.245 6.088-6.263h54.418c25.221 0 45.667-20.446 45.667-45.667s-20.446-45.667-45.667-45.667zM613.75 654.75S619.5 684 597.25 684H575v.001H198.999c-25.221 0-45.667-20.446-45.667-45.667s20.446-45.667 45.667-45.667h102.386c16.53-.958 20.449-10.5 20.449-10.5l.09.046 133.648-231.486-.04-.008c2.437-4.472.408-8.697.055-9.366l-44.137-76.447c-12.611-21.842-5.127-49.772 16.715-62.382 21.842-12.611 49.771-5.127 62.382 16.715l20.47 35.456 20.434-35.393c12.61-21.842 40.54-29.326 62.382-16.715s29.326 40.541 16.715 62.382L424.573 587.086c-.813 1.961-1.062 5.039 4.988 5.581h111.165l.024 1.083s64.25 1 73 61z"
        fill="#fff"
      />
    </svg>
  )
}

export default Svg
