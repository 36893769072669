import * as React from "react"

function Svg(props) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 168 168" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M88.1297 95.1675H109.23C115.23 95.1675 119.03 91.8675 119.03 86.5675C119.03 81.5675 115.23 77.9675 109.23 77.9675H88.1297C87.4297 77.9675 86.8297 78.5675 86.8297 79.1675V93.9675C86.9297 94.6675 87.4297 95.1675 88.1297 95.1675Z"
        fill="white"
      />
      <path
        d="M88.1297 129.268H109.93C116.63 129.268 120.63 125.868 120.63 119.868C120.63 114.668 116.83 110.468 109.93 110.468H88.1297C87.4297 110.468 86.8297 111.068 86.8297 111.668V127.968C86.9297 128.768 87.4297 129.268 88.1297 129.268Z"
        fill="white"
      />
      <path
        d="M165.33 0.367423L102.93 62.5674H113.13C129.03 62.5674 138.43 72.5674 138.43 83.4674C138.43 92.0674 132.63 98.3674 126.43 101.167C125.43 101.567 125.43 102.967 126.53 103.367C133.73 106.167 138.83 113.667 138.83 122.467C138.83 134.867 130.53 144.767 114.43 144.767H70.2297C69.5297 144.767 68.9297 144.167 68.9297 143.567V96.4674L0.429705 164.667C-0.470295 165.567 0.129705 167.167 1.4297 167.167H166.13C166.73 167.167 167.23 166.667 167.23 166.067V1.26742C167.53 0.167416 166.13 -0.432577 165.33 0.367423Z"
        fill="white"
      />
    </svg>
  )
}

export default Svg
