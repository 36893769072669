import * as React from "react"

function Svg(props) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0)">
        <rect
          x="36.3016"
          y="51.3516"
          width="174.704"
          height="187.126"
          transform="rotate(-0.443245 36.3016 51.3516)"
          fill="white"
        />
        <rect
          x="36.0059"
          y="10.114"
          width="143.681"
          height="51.1759"
          transform="rotate(-0.443245 36.0059 10.114)"
          fill="white"
        />
        <path
          d="M224.297 49.1405L177.422 2.26551C175.948 0.803103 173.952 -0.0118382 171.875 -0.000119483H46.875C33.9307 -0.000119483 23.4375 10.493 23.4375 23.4374V226.562C23.4375 239.507 33.9307 250 46.875 250H203.125C216.069 250 226.562 239.507 226.562 226.562V54.6874C226.575 52.6107 225.759 50.6151 224.297 49.1405ZM210.938 226.562C210.938 230.877 207.44 234.375 203.125 234.375H46.875C42.5601 234.375 39.0625 230.877 39.0625 226.562V23.4374C39.0625 19.1224 42.5601 15.6249 46.875 15.6249H164.062V46.8749C164.062 55.5043 171.058 62.4999 179.688 62.4999H210.938V226.562Z"
          fill="black"
        />
        <path d="M195.312 187.5H179.688V203.125H195.312V187.5Z" fill="black" />
        <path d="M195.312 109.375H54.6875V125H195.312V109.375Z" fill="black" />
        <path d="M195.312 148.438H54.6875V164.062H195.312V148.438Z" fill="black" />
        <path d="M164.062 187.5H54.6875V203.125H164.062V187.5Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="250" height="250" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Svg
