import * as React from "react"

function Svg(props) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="250" height="250" fill="white" />
      <path
        d="M68.5145 61.9732C56.4795 63.6649 48.1017 74.7815 49.7772 86.8165L62.7628 179.745L117.412 55.1582L68.5145 61.9732V61.9732Z"
        fill="#6BB435"
      />
      <path
        d="M200.384 139.403L195.277 67.9667C194.374 55.2712 182.903 45.9912 170.288 47.7473L149.247 50.6956L195.567 154.773C198.982 150.536 200.787 145.075 200.384 139.403Z"
        fill="#0099C5"
      />
      <path
        d="M82.6439 202.333C86.2367 202.736 89.9905 202.269 93.6316 200.722L173.172 167.082L135.359 82.1284L82.6439 202.333Z"
        fill="#FF7800"
      />
    </svg>
  )
}

export default Svg
